var md = new MobileDetect(window.navigator.userAgent);
var ua = detect.parse(navigator.userAgent);
// console.log("mobile");
// console.log(md.mobile()); // 'Sony'
// console.log(md.phone()); // 'Sony'
// console.log(md.tablet()); // null
// console.log(md.userAgent()); // 'Safari'
// console.log(md.os()); // 'AndroidOS'
// console.log(md.is("iPhone")); // false
// console.log(md.is("bot")); // false
// console.log(md.version("Webkit")); // 534.3
// console.log(md.versionStr("Build")); // '4.1.A.0.562'
// console.log(md.match("playstation|xbox")); // false

// console.log("detect");
// console.log(ua.browser);
// console.log(ua.device);
// console.log(ua.os);
//test 2
var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;


var scrollI = document.getElementById("scrollIcon");
var ismobile = false;
var openMega = null;
var currentPage = 1;

if (md.mobile())
    ismobile = true;

// Define the Function targetBlank()
function targetBlank() {
    // remove subdomain of current site's url and setup regex
    var internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    var a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (var i = 0; i < a.length; i++) {
        var href = a[i].host; // set the host of each link
        if ((!internal.test(href) && !/^javascript/.test(a[i].getAttribute("href"))) || /\.pdf$/.test(a[i].getAttribute("href"))) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}

(function () {
    "use strict";

    // Run the function targetBlank()
    targetBlank();
    if (document.querySelectorAll(".scroll-top").length > 0) {
        document.querySelectorAll('.scroll-top')[0].addEventListener("click", function (event) {
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': 0
            });
        });
    }

    if (document.querySelectorAll(".wechsler").length > 0) {
        const grid = document.querySelectorAll('div.grid')[0];
        const list = document.querySelectorAll('div.table-listview')[0];
        const gridb = document.querySelectorAll('.wechsler .bgrid')[0]
        const listb =  document.querySelectorAll('.wechsler .blist')[0]
        listb.addEventListener("click", function (event) {
            list.classList.add("show");
            grid.classList.remove("show");
            listb.classList.add("active");
            gridb.classList.remove("active");
        });
        gridb.addEventListener("click", function (event) {
            grid.classList.add("show");
            list.classList.remove("show");
            gridb.classList.add("active");
            listb.classList.remove("active");
        });
    }

    if (document.querySelectorAll("button.navbar-toggler").length > 0) {

        anime({
            targets: '.navbar-nav li',
            translateY: 200,
            opacity: 0,
            delay: anime.stagger(20)
        });
        // Mobile toggler
        document.querySelectorAll("button.navbar-toggler")[0].addEventListener("click", function (event) {
            var target = document.querySelectorAll('.navbar-collapse')[0];
            this.className = (this.className + " open").replace(/ open open/, "");
            target.className = (target.className + " show").replace(/ show show/, "");

            var darker = document.querySelectorAll('.content-darker')[0];
            if (target.classList.contains("show")) {
                darker.classList.add("show");
                setTimeout(function () {
                    anime({
                        targets: '.navbar-collapse li',
                        translateY: 0,
                        opacity: 1,
                        easing: 'easeOutQuad',
                        duration: 300,
                        delay: anime.stagger(60)
                    });
                }, 450);
            } else {
                anime({
                    targets: '.navbar-collapse li',
                    translateY: 200,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
                darker.classList.remove("show");
            }
        });
    }


    if (document.querySelectorAll("button.navbar-toggler").length > 0) {


    }




    // Wait for Images
    imagesLoaded(document.querySelector("main"), function (instance) {


        if (document.querySelectorAll(".glide-banner").length > 0) {
            var mybanner = document.getElementById("mybanner");
            if(mybanner) {
                if (mybanner.clientHeight + 260 > y)
                    scrollI.style.opacity = 1;
            }


            var scount = document.querySelectorAll(".glide__slide").length;
            var track = document.querySelectorAll(".glide__track")[0];
            console.log(scount)
            var start = Math.ceil(scount*Math.random())-1;
            console.log(start)
            if(start<0)
                start=0;
            var glide = new Glide('.glide-banner',{
                type: 'slider',
                startAt: start,
                hoverpause: false,
                autoplay: 10000
            }).mount()

            track.addEventListener("click", function (event) {
                glide.go('>');
            })


            glide.on('run.before', function() {
                console.log('before')
                // Handler logic ...
                var tlinit = anime.timeline({
                    easing: 'linear'
                });
                tlinit.add({
                    targets: '.claim-left-single',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 100
                }, '+=0');
                tlinit.add({
                    targets: '.claim-right-single',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 100
                }, '+=0');
            })

            glide.on('run.after', function() {
                console.log('after')
                // Handler logic ...
                var tlinit = anime.timeline({
                    easing: 'linear'
                });
                tlinit.add({
                    targets: '.claim-left-single',
                    opacity: 1,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 450
                }, '+=1200');
                tlinit.add({
                    targets: '.claim-right-single',
                    opacity: 1,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 350
                }, '+=200');
            })
        }
        // fadein main
        var tlinit = anime.timeline({
            easing: 'linear'
        });

        tlinit.add({
            targets: '.lds-dual-ring',
            opacity: 0,
            easing: 'easeInOutSine',
            duration: 50
        }, '+=0');
        tlinit.add({
            targets: '.navbar',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 250
        }, '+=0');
        tlinit.add({
            targets: 'main',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=200');
        tlinit.add({
            targets: '.claim-left',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 450
        }, '+=0');
        tlinit.add({
            targets: '.claim-right',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 350
        }, '+=200');

        anime({
            targets: '.scroll-circle',
            loop: true,
            translateY: 5,
            easing: 'easeInOutSine',
            duration: 650
        });
    });
})();

// navbar shrink
window.addEventListener("scroll", function () {
    var nav = document.getElementById("mainNav");

    if (window.scrollY > 140) {
        nav.classList.add("shrink");
        if(scrollI)
            scrollI.classList.add("hide");
    } else {
        nav.classList.remove("shrink");
    }

    if (document.querySelectorAll(".scroll-top").length > 0) {
        if (window.scrollY > 240) {
            document.querySelectorAll('.scroll-top')[0].classList.add('show');
        } else {
            document.querySelectorAll('.scroll-top')[0].classList.remove('show');
        }
    }
});

// browser-warning
if (ua.browser.family === "IE") {
    const warning = document.querySelectorAll('.browser-warning');
    warning[0].className = 'browser-warning show';
}
